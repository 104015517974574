<template>
    <div>
        <div v-if="!addOrEditReady">
            <LoadingSpinner class="col-12" />
        </div>

        <div v-if="addOrEditReady">
            <form @submit.prevent="checkForm">
                <div class="row">
                    <div class="col-12" v-if="form_message !== ''">
                        <ErrorAlert :messageI18n="form_message" />
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <label for="date_ouverture" class="col-form-label">{{ $t("horse.date_ouverture") }} *</label>
                            <e-datepicker id="date_ouverture" v-model="form.date_ouverture" :class="{ 'is-invalid': errors && errors.indexOf('DateOuverture') > -1 }"></e-datepicker>
                        </div>
                    </div>

                    <div class="col-12" v-if="horsepension_id">
                        <div class="form-group">
                            <label for="date_cloture" class="col-form-label">{{ $t("horse.date_cloture") }}</label>
                            <e-datepicker id="date_cloture" :min-date="form.date_ouverture" v-model="form.date_cloture" :class="{ 'is-invalid': errors && errors.indexOf('DateCloture') > -1 }"></e-datepicker>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <label for="pension_type" class="col-form-label">{{ $t("horse.pension") }} * 
                                <router-link :to="{ name: url_pension_config, params: params_pension_config}">
                                    {{ $t('horse.config_pension') }}
                                </router-link></label>
                            <e-select
                                v-model="form.pension_type"
                                id="pension_type"
                                track-by="label"
                                label="label"
                                :placeholder="labelTitlePensionType"
                                :selectedLabel="selectedLabel"
                                :options="pension_type"

                                group-values="pension_type"
                                group-label="accountingplan_label"
                                :group-select="false"

                                :searchable="true"
                                :allow-empty="true"
                                :show-labels="false"
                                :close-on-select="true"
                                :class="{ 'is-invalid': errors && errors.indexOf('PensionType') > -1 }"
                            >
                                <template slot="singleLabel" slot-scope="{ option }">{{ option.label }}</template>
                                <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                                <template slot="noResult">{{ $t('global.no_result') }}</template>
                            </e-select>
                        </div>
                    </div>

                    <div class="col-12" v-if="hasFacturationAccess && form.pension_type">
                        <div class="form-group" v-if="form.pension_type.article">
                            <div class="row">
                                <div class="col"><label for="qte">{{ $t('acte.articles') }}</label></div>
                                <div class="col"><label for="qte">{{ $t('acte.ht_before_remise') }}</label></div>
                                <div class="col"><label for="qte">{{ $t('acte.remise') }}</label></div>
                                <div class="col"><label for="qte">{{ $t('acte.ht') }}</label></div>
                                <div class="col"><label for="qte">{{ $t('acte.type') }}</label></div>
                            </div>
                            <div class="row" v-for="(article, index) in form.pension_type.article" :key="index">
                                <div class="col">
                                    <span class="input-group-text">
                                        {{ article.articles_label }}
                                    </span>
                                </div>
                                <div class="col">
                                    <b-form-input 
                                        type="text" 
                                        v-model="article.horsepensionarticle_ht_before_discount" 
                                        class="form-control"
                                        @change="reloadConditionLine(article.horsepensionarticle_id)"
                                    ></b-form-input>
                                </div>
                                <div class="col">
                                    <div class="d-flex">
                                        <b-input v-if="article.horsepensionarticle_discount_type.invoicediscounttype_type != 'none'" type="text" class="form-control" v-model="article.horsepensionarticle_discount_value" :disabled="article.disabled" @change="setDiscountValueCondition(article.horsepensionarticle_discount_value, article.horsepensionarticle_id)"/>
                                        <e-select
                                            v-model="article.horsepensionarticle_discount_type"
                                            id="discount_type"
                                            track-by="invoicediscounttype_id"
                                            label="invoicediscounttype_label"
                                            :selectedLabel="selectedLabel"
                                            :options="discount_type_formatted"
                                            :searchable="true"
                                            :allow-empty="false"
                                            :show-labels="false"
                                            @open="onTableSelectOpen"
                                            @close="onTableSelectClose"
                                            @input="setDiscountValueCondition(article.horsepensionarticle_discount_value, article.horsepensionarticle_id)"
                                        >
                                            <template slot="singleLabel" slot-scope="{ option }">{{ $t(option.invoicediscounttype_label) }}</template>
                                            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                                        </e-select>
                                    </div>
                                </div>
                                <div class="col">
                                    <b-form-input 
                                        type="text" 
                                        v-model="article.horsepensionarticle_ht" 
                                        class="form-control"
                                        readonly
                                    ></b-form-input>
                                </div>
                                <div class="col">
                                    <e-select
                                        v-model="article.facturation_type"
                                        id="facturation_type"
                                        :placeholder="getTrad('horse.type_facturation')"
                                        :selectedLabel="selectedLabel"
                                        :options="facturation_type"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        :close-on-select="true"
                                        :disabled="true"
                                        :class="{ 'is-invalid': errors && errors.indexOf('facturation_type') > -1 }"
                                    >
                                    </e-select>
                                </div>
                            </div>
                        </div>

                        <template v-if="hasFacturationAccess && form.close_previous && form.pension_type && Object.keys(form.pension_type).length > 0 && form.pension_type.article.filter(article => article.pensionarticle_monthly == 0 && article.pensionarticle_invoiceable_in == 1).length > 0">
                            <label>{{ $t('horse.article_facturer_jour_entree') }}</label>
                            <p v-for="(article_sortie, k) in form.pension_type.article.filter(article => article.pensionarticle_monthly == 0 && article.pensionarticle_invoiceable_in == 1)" :key="k">
                                {{ article_sortie.articles_label }}
                            </p>
                        </template>
                    </div>

                    <div class="col-12" v-if="!horsepension_id">
                        <div class="form-group">
                            <label for="close_previous" class="col-form-label"></label>
                            <b-form-checkbox v-model="form.close_previous" switch>
                                {{ $t("horse.close_previous_pension") }}
                            </b-form-checkbox>
                        </div>

                        <div class="form-group" v-if="form.close_previous">
                            <label for="date_fermeture" class="col-form-label">{{ $t("horse.date_fermeture") }} *</label>
                            <e-datepicker id="date_fermeture" v-model="form.date_fermeture" :class="{ 'is-invalid': errors && errors.indexOf('DateFermeture') > -1 }"></e-datepicker>
                        </div>

                        <template v-if="hasFacturationAccess && form.close_previous && current_horse_pension && Object.keys(current_horse_pension.pension).length > 0 && current_horse_pension.pension.article.filter(article => article.pensionarticle_monthly == 0 && article.pensionarticle_invoiceable_out == 1).length > 0">
                            <label>{{ $t('horse.article_facturer_jour_sortie') }}</label>
                            <p v-for="(article_sortie, k) in current_horse_pension.pension.article.filter(article => article.pensionarticle_monthly == 0 && article.pensionarticle_invoiceable_out == 1)" :key="k">
                                {{ article_sortie.articles_label }}
                            </p>
                        </template>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script type="text/javascript">
    import Common from '@/assets/js/common.js'
    import { EventBus } from 'EventBus'
    import Navigation from "@/mixins/Navigation.js"
    import Tools from "@/mixins/Tools.js"
    import HorseMixin from "@/mixins/Horse.js"
    import AccountingMixin from "@/mixins/Accounting.js"
    import PensionMixin from "@/mixins/Pension.js"
	import TableSelectMixin from "@/mixins/TableSelect.js"
	import InvoiceMixin from "@/mixins/Invoice.js"

    export default {
        name: "PensionAjout",
        props: ['horsepension_id', 'horse_pension', 'addOrEditReady', 'disableButton', 'horse', 'horse_ids'],
        mixins: [Navigation, Tools, HorseMixin, AccountingMixin, PensionMixin, InvoiceMixin, TableSelectMixin],
        data () {
            return {
                selectedLabel: this.getTrad("global.selected_label"),
                labelTitlePensionType: this.getTrad("horse.search_pension_type"),
                pension_type: [],
                facturation_type: [
                    'pension',
                    'frais'
                ],
                url_pension_config: 'accountingListe',
                params_pension_config: {},
                form_message: "",
                form: {},
                errors: [],
                discount_type: [],
                current_horse_pension: null,
                default_form: {
                    date_ouverture: null,
                    date_cloture: null,
                    date_fermeture: null,
                    pension_type: {},
                    close_previous: true
                },
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.form_message = ""
                this.$emit('update:addOrEditReady', false)
                this.setDisabledButton(false)
                this.discount_type = await this.loadDiscountType()

                this.form = this.default_form

                let horse_pension = {}
                if(this.horse_pension)
                {
                    horse_pension = this.horse_pension.filter(horse_pension => horse_pension.horsepension_id == this.horsepension_id)[0]
                    this.current_horse_pension = this.horse_pension[0]
                }

                if(this.horsepension_id)
                {
                    if(horse_pension)
                    {
                        this.form.date_ouverture = new Date(horse_pension.horsepension_start)
                        const articles = horse_pension.horsepension_article.map(article => {
                            const element = {
                                articles_label: article.horsepensionarticle_article.articles_label,
                                articles_id: article.horsepensionarticle_article.articles_id,
                                horsepensionarticle_ht: article.horsepensionarticle_ht,
                                horsepensionarticle_id: article.horsepensionarticle_id,
                                vat: article.horsepensionarticle_article.vat,
                                horsepensionarticle_discount_value: article.horsepensionarticle_discount_value,
                                horsepensionarticle_ht_before_discount: article.horsepensionarticle_ht_before_discount,
                                facturation_type: article.horsepensionarticle_facturationtype
                            }

                            if(article.discount_type == null) {
                                element.horsepensionarticle_discount_type = {invoicediscounttype_id: 0, invoicediscounttype_label: "-", invoicediscounttype_type: "none"}
                            }
                            else {
                                element.horsepensionarticle_discount_type = article.discount_type
                            }

                            return element
                        })

                        this.form.pension_type = {
                            id: horse_pension.pension.pension_id, 
                            label: horse_pension.pension.pension_label, 
                            accountingplan_id: horse_pension.pension.pension_accountingplan, 
                            article: articles
                        }
                        
                        if (horse_pension.horsepension_end)
                        {
                            this.form.date_cloture = new Date(horse_pension.horsepension_end)
                        }
                    }
                    else
                    {
                        this.form_message = "error.LEP"
                        this.setDisabledButton(true)
                    }
                }

                if(this.horse_ids) {
                    this.form.date_ouverture = new Date()
                }

                if (this.form.date_ouverture === null && !this.horse_ids)
                {
                    this.getHorseActualPlace(this.horse.horse_id)
                    .then(currentLocation => {
                        if (currentLocation)
                        {
                            this.form.date_ouverture = new Date(currentLocation.mouvement_date)
                        }
                        else
                        {
                            this.form.date_ouverture = new Date()
                        }
                    })
                }


                if(Object.keys(this.pension_type).length == 0)
                {
                    let accountingplan_key = null
                    const accounting_plan = await this.loadAccountingPlansPensionType()

                    if(accounting_plan)
                    {
                        if(accounting_plan.length == 1) {
                            this.url_pension_config = 'AccountingFichePension'
                            this.params_pension_config = {
                                accountingplan_id: accounting_plan[0].accountingplan_id
                            }
                        }

                        for(let i = 0; i < accounting_plan.length; i++)
                        {
                            if(accounting_plan[i].pension_type)
                            {
                                for(let j = 0; j < accounting_plan[i].pension_type.length; j++)
                                {
                                    accountingplan_key = this.pension_type.filter(pensiontype => pensiontype.accountingplan_id == accounting_plan[i].accountingplan_id)[0];

                                    for (let k = 0; k < accounting_plan[i].pension_type[j].article.length; k++) {
                                        accounting_plan[i].pension_type[j].article[k].horsepensionarticle_ht_before_discount = accounting_plan[i].pension_type[j].article[k].articles_ht
                                        accounting_plan[i].pension_type[j].article[k].horsepensionarticle_ht = accounting_plan[i].pension_type[j].article[k].articles_ht
                                        accounting_plan[i].pension_type[j].article[k].horsepensionarticle_id = k
                                        accounting_plan[i].pension_type[j].article[k].horsepensionarticle_discount_type = {invoicediscounttype_id: 0, invoicediscounttype_label: "-", invoicediscounttype_type: "none"}
                                        accounting_plan[i].pension_type[j].article[k].facturation_type = accounting_plan[i].pension_type[j].article[k].pensionarticle_facturationtype
                                    }

                                    if(accountingplan_key)
                                    {
                                        accountingplan_key.pension_type.push({
                                            id: accounting_plan[i].pension_type[j].pension_id,
                                            label: accounting_plan[i].pension_type[j].pension_label,
                                            accountingplan_id: accounting_plan[i].accountingplan_id,
                                            article: accounting_plan[i].pension_type[j].article
                                        })
                                    }
                                    else
                                    {
                                        this.pension_type.push({
                                            accountingplan_id: accounting_plan[i].accountingplan_id,
                                            accountingplan_label: accounting_plan[i].accountingplan_label,
                                            pension_type: [{
                                                id: accounting_plan[i].pension_type[j].pension_id,
                                                label: accounting_plan[i].pension_type[j].pension_label,
                                                accountingplan_id: accounting_plan[i].accountingplan_id,
                                                article: accounting_plan[i].pension_type[j].article
                                            }]
                                        })
                                    }
                                }
                            }
                        }
                    }
                }

                this.$emit('update:addOrEditReady', true)
            },
            setDisabledButton: async function(disabled) {
                this.$emit('update:disableButton', disabled)
            },
            checkForm: async function () {
                this.form_message = ""
                this.errors = []

                if(!this.form.date_ouverture)
                {
                    this.errors.push("DateOuverture")
                }

                if(!this.form.pension_type || Object.keys(this.form.pension_type).length == 0)
                {
                    this.errors.push("PensionType")
                }

                if(this.horsepension_id && this.form.date_cloture && this.form.date_cloture < this.form.date_ouverture)
                {
                    this.errors.push("DateCloture")
                }

                if(!this.form.date_cloture)
                {
                    this.form.date_cloture = null
                }

                if(!this.form.date_cloture)
                {
                    this.form.date_cloture = null
                }

                if(this.form.close_previous && !this.form.date_fermeture)
                {
                    this.errors.push("DateFermeture")
                }

                if(this.errors.length == 0)
                {
                    let check_date = null
                    let all_retour = []

                    if(this.horsepension_id)
                    {
                        check_date = await this.checkDatePension(this.horse.horse_id, this.form.date_ouverture, this.form.date_cloture, this.horsepension_id)
                    }
                    else if (this.form.close_previous)
                    {
                        check_date = {
                            retour: {
                                type: "success"
                            }
                        }
                    }
                    else
                    {
                        if(this.horse_ids) {
                            await Common.asyncForEach(this.horse_ids, async (horse_id) => {
                                check_date = await this.checkDatePension(horse_id, this.form.date_ouverture, null)
                                all_retour.push(check_date.retour.type)
                            })
                        }
                        else {
                            check_date = await this.checkDatePension(this.horse.horse_id, this.form.date_ouverture, null)
                        }
                    }

                    if((this.horse_ids && all_retour.filter(r => r == 'error').length == 0) || check_date.retour.type == "success")
                    {
                        let result = null
                        if(this.horsepension_id)
                        {
                            result = await this.editPension(
                                this.horsepension_id,
                                this.horse.horse_id,
                                this.form.date_ouverture,
                                this.form.date_cloture,
                                this.form.pension_type.id,
                                this.form.pension_type.article
                            );
                        }
                        else
                        {
                            if(!this.horse_ids) {
                                let horse_no_tiers = await this.checkHorseHasTiersOnDate(this.horse.horse_id, this.form.date_ouverture)

                                if(horse_no_tiers) {
                                    this.infoToast("toast.unique_horse_no_tiers", {horse_nom: horse_no_tiers})
                                }
                            }

							try {
                                if(this.horse_ids) {
                                    await Common.asyncForEach(this.horse_ids, async (horse_id) => {
                                        result = await this.addPension(
                                            horse_id,
                                            this.form.date_ouverture,
                                            null,
                                            this.form.pension_type.id,
                                            this.form.close_previous,
                                            this.form.pension_type.article,
                                            this.form.date_fermeture
                                        )
                                    })
                                }
                                else {
                                    result = await this.addPension(
                                        this.horse.horse_id,
                                        this.form.date_ouverture,
                                        null,
                                        this.form.pension_type.id,
                                        this.form.close_previous,
                                        this.form.pension_type.article,
                                        this.form.date_fermeture
                                    )
                                }
							}
							catch {
								result = false
							}
                        }

                        if(result)
                        {
                            return true
                        }
                        else
                        {
                            this.form_message = "error.LEP"
                        }
                    }
                    else
                    {
                        this.form_message = "error.PD"
                    }
                    
                }

                return false
            },

            async setDiscountValueCondition(discount_value, horsepensionarticle_id) {
				const elem_to_edit = this.form.pension_type.article.find(elem => horsepensionarticle_id == elem.horsepensionarticle_id)
				elem_to_edit.horsepensionarticle_discount_value = discount_value
				this.reloadConditionLine(horsepensionarticle_id)
			},

			async reloadConditionLine(horsepensionarticle_id) {
				const elem_to_edit = this.form.pension_type.article.find(elem => horsepensionarticle_id == elem.horsepensionarticle_id)

				// on recalcule d'abord le prix ht unitaire après remise
                const ht_before_discount = elem_to_edit.horsepensionarticle_ht_before_discount.toString().replace(',', '.').replace(/\s/g, '')
				const discount_type = elem_to_edit.horsepensionarticle_discount_type

                if(discount_type.invoicediscounttype_type != "none" && elem_to_edit.horsepensionarticle_discount_value)
                {
                    const discount_value = elem_to_edit.horsepensionarticle_discount_value.toString().replace(',', '.')

                    if(discount_type.invoicediscounttype_type == "numeraire") {
                        elem_to_edit.horsepensionarticle_ht = ht_before_discount - discount_value
                    }
                    else if(discount_type.invoicediscounttype_type == "pourcentage" && discount_value > 0) {
                        elem_to_edit.horsepensionarticle_ht = ht_before_discount - (ht_before_discount * (discount_value / 100))
                    }
                    else {
                        elem_to_edit.horsepensionarticle_ht = ht_before_discount
                    }
                }
				else {
					elem_to_edit.horsepensionarticle_ht = ht_before_discount
				}

				const ht = elem_to_edit.horsepensionarticle_ht

				// ensuite on calcule le HT total et le TTC
				elem_to_edit.horsepensionarticle_ht = await this.priceFormat(ht)
				elem_to_edit.horsepensionarticle_ttc = await this.priceFormat(ht * (1 + elem_to_edit.vat.vat_value))
			},
        },
        computed: {
            hasFacturationAccess() {
                return this.$store.state.userAccess.hasFacturationAccess
            },
            discount_type_formatted(){
				let tab = []

				tab.push({
					invoicediscounttype_id: 0,
					invoicediscounttype_label: "-",
					invoicediscounttype_type: "none"
				})

				for(let i = 0; i < this.discount_type.length; i++) {
					let current = this.discount_type[i]
					current.invoicediscounttype_label = this.getTrad(current.invoicediscounttype_label+"_short")
					tab.push(current)
				}

				return tab
			}
        },
        watch: {
            'form.date_ouverture'(value){
                if(value != null && value != undefined)
                {
                    this.form.date_fermeture = new Date(value)
                    this.form.date_fermeture.setDate(this.form.date_fermeture.getDate()-1);
                }

            }
        },
        components: {
            LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            ErrorAlert : () => import('GroomyRoot/components/Alert/ErrorAlert')
        }
    }
</script>
